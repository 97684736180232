import React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import profilePic from "../../assets/images/profile.jpg";
import twitterIcon from "../../assets/icons/twitter.png";
import linkedinIcon from "../../assets/icons/linkedin.png";
import studentIcon from "../../assets/icons/student-with-graduation-cap.png";
import githubIcon from "../../assets/icons/github.png";
import Header from "../../components/header"; // Importar o Header

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home-main-container">
      <Header />
      <section>
        <div className="home-container">
          <div className="home-title">
            <div className="portrait--area">
              <div className="circular--portrait">
                <div className="foto">
                  <img src={profilePic} alt="foto-perfil" />
                </div>
              </div>
            </div>
            <div className="header">
              <a rel="noreferrer" href="./">
                Henrique Torres de Almeida Rodrigues
              </a>
            </div>

            <div className="header-content-divisor" />

            <div className="subtitle">
              <h4>{t("home.description")}</h4>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer>
        <div className="home-external-links">
          <div className="external-links-item">
            <a
              target="_blank"
              rel="noreferrer"
              href="http://twitter.com/henritar"
            >
              <img src={twitterIcon} alt="twitter" />
            </a>
          </div>
          <div className="external-links-item">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/henritar/"
            >
              <img src={linkedinIcon} alt="linkedin" />
            </a>
          </div>
          <div className="external-links-item">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.github.com/henritar/"
            >
              <img src={githubIcon} alt="github" />
            </a>
          </div>
          <div className="external-links-item">
            <a rel="noreferrer" href="./curriculum">
              <img src={studentIcon} alt="cv" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
