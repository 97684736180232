const translation = {
    translation: {
        under_construction: "Em Construção!",
        home: {
            title: "Home",
            description: "Bem-vindo ao meu site/blog! Este é um espaço onde compartilho conhecimentos e curiosidades sobre minhas paixões: programação, jogos e linguística!"
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Desenvolvedor de Software",
        about: "Sobre",
        blog: {
            title: "Blog",
            viewOnGithub: "Ver no Github",
            posts: [
                {
                    id: 1,
                    title: "Dungeon Command: Explorando o Padrão de Projeto Command",
                    description: "Dungeon Command é um jogo de puzzle 3D desenvolvido como exemplo da aplicação do Padrão de Projeto Command, onde o jogador controla um personagem em um tabuleiro repleto de tiles, barreiras e coletáveis. O objetivo é coletar todos os itens espalhados pelo tabuleiro enquanto enfrenta bloqueios que exigem rotações estratégicas do tabuleiro para ajustar as barreiras e desbloquear novos caminhos.",
                    image: "/DungeonCommand.png",
                    unityPath: "/unity-build/CommandPattern/index.html",
                    gitHubUrl: "https://github.com/henritar/UnityCommandPatternExample",
                    content: [
                        {
                            sectionTitle: "Visão Geral do Post",
                            paragraphs: [
                                "Neste post do blog, exploraremos como um jogo de quebra-cabeça 3D — Dungeon Command — implementa o Command Design Pattern para gerenciar o movimento do jogador, a rotação do tabuleiro e outras ações críticas.",
                                "O objetivo é mostrar como o Command Pattern traz clareza, flexibilidade e um mecanismo fácil de desfazer/refazer (Undo/Redo) para um jogo onde cada movimento importa!"
                            ]
                        },
                        {
                            sectionTitle: "O Que É Dungeon Command?",
                            paragraphs: [
                                "Dungeon Command é um jogo de quebra-cabeça 3D no qual você guia um personagem por um ambiente baseado em tiles.",
                                "Aqui estão alguns pontos-chave:"
                            ],
                            bulletPoints: [
                                "Mecânica Principal: Mover o personagem pelo tabuleiro e girar todo o cenário.",
                                "Objetivo: Coletar todos os itens do nível enquanto navega por barreiras e resolve quebra-cabeças espaciais.",
                                "Recurso Único: A capacidade de girar todo o tabuleiro, mudando completamente o layout e abrindo novos caminhos."
                            ]
                        },
                        {
                            sectionTitle: "Por Que Usar o Command Pattern?",
                            paragraphs: [
                                "O Command Pattern é excelente para separar 'o que fazer' (os comandos) de 'quando fazer' (o invoker).",
                                "Nos jogos, ele ajuda de várias maneiras:"
                            ],
                            bulletPoints: [
                                "Separação Clara de Responsabilidades: O tratamento de entrada não é misturado com a lógica de ação do jogo.",
                                "Desfazer/Refazer Mais Fácil: Cada comando sabe como se desfazer, simplificando o gerenciamento do histórico.",
                                "Suporte a Operações Assíncronas: Algumas ações levam tempo (como a animação de rotação do tabuleiro), e o Command Pattern lida com isso de forma elegante.",
                                "Extensibilidade: É fácil adicionar novos comandos sem alterar a estrutura geral.",
                                "Testabilidade: Como cada comando é independente, pode ser testado isoladamente."
                            ]
                        },
                        {
                            sectionTitle: "Implementação do Command Pattern",
                            paragraphs: [
                                "O jogo define uma interface para comandos, permitindo funcionalidade de execução e desfazer.",
                                "Aqui está um exemplo da interface ICommand usada no jogo:"
                            ],
                            code: [
                                {
                                    language: "csharp",
                                    content: "public interface ICommand {\n    bool Execute();\n    void Undo();\n}"
                                }
                            ]
                        },
                        {
                            sectionTitle: "Comandos Concretos",
                            subSections: [
                                {
                                    subSectionTitle: "MoveCommand",
                                    paragraphs: [
                                        "Função: Move o jogador nas quatro direções cardinais.",
                                        "Pontos-chave:"
                                    ],
                                    bulletPoints: [
                                        "Armazena a posição anterior para funcionalidade de desfazer.",
                                        "Verifica colisões com barreiras e limites do tabuleiro.",
                                        "Ativa tiles quando o jogador pisa sobre eles e coleta itens encontrados."
                                    ],
                                    usage: "Ativado quando o jogador pressiona W, A, S, D."
                                },
                                {
                                    subSectionTitle: "RotateBoardCommand",
                                    paragraphs: [
                                        "Função: Rotaciona o tabuleiro (sentido horário ou anti-horário).",
                                        "Pontos-chave:"
                                    ],
                                    bulletPoints: [
                                        "Implementa IAsyncCommand para gerenciar animações de rotação.",
                                        "Impede novas rotações enquanto uma está em andamento.",
                                        "Executa automaticamente um desfazer se a rotação levar a um estado inválido.",
                                        "Recalcula as barreiras após a rotação."
                                    ],
                                    usage: "Ativado por Q (anti-horário) ou E (horário)."
                                }
                            ]
                        },
                        {
                            sectionTitle: "PlayerController como Invoker",
                            paragraphs: [
                                "O PlayerController é o 'cérebro' que reage à entrada do usuário e chama os comandos apropriados.",
                                "Os comandos executados são armazenados em uma pilha para suportar a funcionalidade de desfazer."
                            ],
                            code: [
                                {
                                    language: "csharp",
                                    content: "public void ExecuteCommand(ICommand command) {\n    if (command.Execute()) {\n        commandHistory.Push(command);\n    }\n    CollectableManager.Instance.CheckLevelComplete();\n}"
                                }
                            ]
                        },
                        {
                            sectionTitle: "Como Jogar",
                            paragraphs: [
                                "Dungeon Command é controlado usando as seguintes teclas:"
                            ],
                            bulletPoints: [
                                "Movimento: W, A, S, D",
                                "Rotação do Tabuleiro: Q (anti-horário) e E (horário)",
                                "Desfazer Última Ação: Z",
                                "Reiniciar Nível: R"
                            ]
                        },
                        {
                            sectionTitle: "Fluxo do Jogo",
                            paragraphs: [
                                "O loop de jogo consiste nos seguintes passos:"
                            ],
                            bulletPoints: [
                                "Mova-se pelo tabuleiro com WASD.",
                                "Ao pisar em um tile, ele é ativado; algumas barreiras podem aparecer ou desaparecer.",
                                "Gire o tabuleiro com Q ou E se precisar de novos caminhos.",
                                "Colete todos os itens para finalizar o nível.",
                                "Se cometer um erro, pressione Z para desfazer.",
                                "Pressione R para reiniciar o nível se quiser começar do zero."
                            ]
                        },
                        {
                            sectionTitle: "Detalhes Técnicos",
                            subSections: [
                                {
                                    subSectionTitle: "Histórico de Comandos e Desfazer",
                                    paragraphs: [
                                        "Os comandos executados são armazenados em uma Stack<ICommand>.",
                                        "Ao desfazer, o comando mais recente é retirado da pilha e seu método Undo() é chamado.",
                                        "Comandos assíncronos lidam com o desfazer de maneira diferente, aguardando a conclusão da animação em andamento:"
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "private IEnumerator ProcessUndoMove() {\n    isUndoing = true;\n    ICommand lastCommand = commandHistory.Pop();\n    if (lastCommand is IAsyncCommand asyncCommand) {\n        asyncCommand.Undo();\n        while (!asyncCommand.IsCompleted) {\n            yield return null;\n        }\n    } else {\n        lastCommand.Undo();\n    }\n    isUndoing = false;\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "Rotação do Tabuleiro",
                                    paragraphs: [
                                        "Quando o tabuleiro é rotacionado:"
                                    ],
                                    bulletPoints: [
                                        "As posições dos tiles são recalculadas com base na direção da rotação.",
                                        "As barreiras trocam de orientação (horizontal ↔ vertical).",
                                        "A posição do jogador é ajustada para o centro do tile mais próximo.",
                                        "As barreiras são atualizadas de acordo com o novo layout."
                                    ]
                                }
                            ]
                        },
                        {
                            sectionTitle: "Conclusão",
                            paragraphs: [
                                "O Command Pattern fornece uma maneira estruturada e extensível de gerenciar ações em Dungeon Command.",
                                "Ele permite fácil expansão, maior manutenção e um sistema de desfazer embutido."
                            ]
                        },
                        {
                            sectionTitle: "Você Gostou do Command Pattern?",
                            paragraphs: [
                                "Se você achou interessante o Command Design Pattern, fique ligado para os próximos posts!",
                                "Compartilharei mais exemplos de padrões de design em jogos para mostrar como eles podem trazer ordem e organização ao desenvolvimento."
                            ]
                        },
                        {
                            sectionTitle: "Obrigado por Ler!",
                            paragraphs: [
                                "Se você tiver dúvidas ou sugestões, deixe um comentário — eu adoraria ouvir de você!"
                            ]
                        }
                    ],
                },
                {
                    id: 2,
                    title: "Tile Matching Game: Implementando o Padrão Strategy nos Objetivos",
                    description: "Este post explora como o Padrão Strategy é aplicado no sistema de Objetivos do Tile Matching Game. Ele permite diferentes tipos de objetivos sem modificar a lógica central do jogo, garantindo flexibilidade e escalabilidade.",
                    image: "/TileMatchingGame.png",
                    unityPath: "/unity-build/StrategyPattern/index.html",
                    gitHubUrl: "https://github.com/henritar/TileMatchingGame",
                    content: [
                        {
                            sectionTitle: "Visão Geral do Post",
                            paragraphs: [
                                "Este post explica como o Padrão Strategy é usado no Tile Matching Game para lidar com diferentes objetivos do jogo dinamicamente.",
                                "O Padrão Strategy permite que o jogo defina múltiplos tipos de objetivos sem modificar a lógica existente, tornando mais fácil adicionar novos desafios no futuro."
                            ]
                        },
                        {
                            sectionTitle: "O Que é o Padrão Strategy?",
                            paragraphs: [
                                "O Padrão Strategy define uma família de algoritmos, encapsula cada um deles e os torna intercambiáveis.",
                                "Ele permite que o jogo selecione a estratégia apropriada (tipo de objetivo) em tempo de execução sem modificar a lógica principal do jogo."
                            ]
                        },
                        {
                            sectionTitle: "Como é Usado no Tile Matching Game?",
                            paragraphs: [
                                "O sistema de objetivos do jogo é construído usando o Padrão Strategy, permitindo diferentes tipos de objetivos:",
                                "Em vez de codificar diferentes comportamentos de objetivos, o jogo define uma interface IGoal, implementada por diferentes classes de objetivo."
                            ],
                            bulletPoints: [
                                "CollectColorTilesGoal: Requer que o jogador remova um número específico de peças de uma determinada cor.",
                                "CollectTilesPointsGoal: O jogador deve atingir uma pontuação alvo.",
                                "MaxMovesGoal: O jogador deve completar o nível dentro de um número limitado de movimentos."
                            ]
                        },
                        {
                            sectionTitle: "Implementação do Padrão Strategy no Código",
                            paragraphs: [
                                "A interface IGoal define o contrato para todos os tipos de objetivo. Cada classe concreta de objetivo implementa esta interface, garantindo uma estrutura padronizada."
                            ],
                            code: [
                                {
                                    language: "csharp",
                                    content: "public interface IGoal<T> {\n    bool IsGoalCompleted();\n    bool HasFailedGoal();\n    string GetDescription();\n    void UpdateProgress(T progressData);\n    string GetProgress();\n    void SetupGoal(GoalSetup[] setupData);\n}"
                                }
                            ]
                        },
                        {
                            sectionTitle: "Estratégias Concretas de Objetivo",
                            subSections: [
                                {
                                    subSectionTitle: "CollectColorTilesGoal",
                                    paragraphs: [
                                        "Este objetivo requer que o jogador combine um número específico de peças de uma determinada cor.",
                                        "Ele rastreia o número de peças removidas e verifica se o alvo foi atingido."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class CollectColorTilesGoal : IGoal<Tile> {\n    private readonly Dictionary<TileColor, int> _amountByColor = new Dictionary<TileColor, int>();\n    private readonly Dictionary<TileColor, int> _currentByColor = new Dictionary<TileColor, int>();\n    public GoalsEnum Goal => GoalsEnum.ColorTilesGoal;\n\n    public string GetDescription() {\n        return \"You must get:\\n \" + string.Join(\"\\n\", _amountByColor.Select(par => $\"{par.Key} {par.Value} tiles\"));\n    }\n\n    public string GetProgress() {\n        return string.Join(\"\\n\", _amountByColor.Select(par => $\"{_currentByColor[par.Key]}/{par.Value} {par.Key}\\n\"));\n    }\n\n    public bool HasFailedGoal() {\n        return false;\n    }\n\n    public bool IsGoalCompleted() {\n        return _currentByColor.All(par => par.Value >= _amountByColor[par.Key]);\n    }\n\n    public void SetupGoal(Level.GoalSetup[] setupData) {\n        _currentByColor.Clear();\n        _amountByColor.Clear();\n        foreach (var colorGoal in setupData.Where(data => data.goalEnum == GoalsEnum.ColorTilesGoal)) {\n            _amountByColor[colorGoal.tileColor] = colorGoal.tileQuantity;\n            _currentByColor[colorGoal.tileColor] = 0;\n        }\n    }\n\n    public void UpdateProgress(Tile progressData) {\n        _currentByColor[tile.TileData.Color]++;\n        }\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "CollectTilesPointsGoal",
                                    paragraphs: [
                                        "Este objetivo requer que o jogador alcance uma pontuação específica combinando peças.",
                                        "Ele rastreia a pontuação acumulada e determina se o alvo foi atingido."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class CollectTilesPointsGoal : IGoal<int> {\n    private int _amountToCollect;\n    private int _currentScore;\n\n    public GoalsEnum Goal => GoalsEnum.TotalPointsGoal;\n\n    public CollectTilesPointsGoal() {\n        _currentScore = 0;\n    }\n\n    public bool IsGoalCompleted() {\n        return _currentScore >= _amountToCollect;\n    }\n\n    public string GetDescription() {\n        return $\"Collect {_amountToCollect} points!\";\n    }\n\n    public string GetProgress() {\n        return $\"{_currentScore}/{_amountToCollect}\";\n    }\n\n    public void UpdateProgress(int progressData) {\n        _currentScore = score;\n    }\n\n    public bool HasFailedGoal() {\n        return false;\n    }\n\n    public void SetupGoal(GoalSetup[] setupData) {\n        var pointsGoal = setupData.First(data => data.goalEnum == GoalsEnum.TotalPointsGoal);\n        _amountToCollect = pointsGoal.maxPoints;\n        _currentScore = 0;\n    }\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "MaxMovesGoal ",
                                    paragraphs: [
                                        "Este objetivo requer que o jogador complete o nível em um número específico de movimentos.",
                                        "Ele rastreia o número de movimentos realizados e falha se o jogador exceder o limite."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class MaxMovesGoal : IGoal<int> {\n    private int _maxMoves;\n    private int _totalMoves;\n    public GoalsEnum Goal => GoalsEnum.MaxMovesGoal;\n\n    public MaxMovesGoal() {\n        _totalMoves = 0;\n        _maxMoves = int.MaxValue;\n    }\n\n    public string GetDescription() {\n        return $\"Beat this level with less than {_maxMoves} moves!\";\n    }\n\n    public string GetProgress() {\n        return $\"{_maxMoves - _totalMoves} left!\";\n    }\n\n    public bool HasFailedGoal() {\n        return _totalMoves >= _maxMoves;\n    }\n\n    public bool IsGoalCompleted() {\n        return _maxMoves >= _totalMoves;\n    }\n\n    public void SetupGoal(GoalSetup[] setupData) {\n        var maxMovesGoal = setupData.First(data => data.goalEnum == GoalsEnum.MaxMovesGoal);\n        _maxMoves = maxMovesGoal.maxPoints;\n        _totalMoves = 0;\n    }\n\n    public void UpdateProgress(int progressData) {\n        _totalMoves++;\n    }\n}"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            sectionTitle: "Por Que Usar o Padrão Strategy Aqui?",
                            paragraphs: [
                                "O Padrão Strategy oferece vários benefícios para o sistema de Objetivos:",
                                "Torna fácil introduzir novos tipos de objetivo sem modificar a lógica existente.",
                                "O jogo pode alternar dinamicamente entre diferentes objetivos em tempo de execução, permitindo designs de níveis mais flexíveis."
                            ],
                            bulletPoints: [
                                "Encapsulamento: Cada tipo de objetivo é uma classe separada, tornando o código mais fácil de manter.",
                                "Extensibilidade: Novos objetivos podem ser adicionados sem modificar a lógica principal do jogo.",
                                "Modularidade: Cada objetivo se comporta independentemente, tornando fácil testar e depurar."
                            ]
                        },
                        {
                            sectionTitle: "Conclusão",
                            paragraphs: [
                                "O Padrão Strategy é uma maneira poderosa de lidar com diferentes objetivos do jogo de forma escalável e manutenível.",
                                "Ao usar este padrão, o Tile Matching Game garante flexibilidade na definição de objetivos, tornando o jogo mais fácil de expandir no futuro."
                            ]
                        },
                        {
                            sectionTitle: "Você Gostou do Padrão Strategy?",
                            paragraphs: [
                                "Se você achou interessante o Padrão Strategy, fique ligado para os próximos posts!",
                                "Compartilharei mais exemplos de padrões de design em jogos para mostrar como eles podem trazer ordem e organização ao desenvolvimento."
                            ]
                        },
                        {
                            sectionTitle: "Obrigado por Ler!",
                            paragraphs: [
                                "Se você tiver dúvidas ou sugestões, deixe um comentário — eu adoraria discutir com você!"
                            ]
                        }   
                    ]
                },            
            ]
        },
        post: {
            notFound: "Post não encontrado.",
            error: "Ocorreu um erro ao carregar o post.",
            readMore: "Leia mais",
            goBack: "Voltar ao blog",
        },
        contact: "Contato",
        city_country: "Brasília, Brasil",
        profile: "Perfil",
        profile_description1: "Sou um desenvolvedor de jogos ítalo-brasileiro que possui um Certificado Profissional Unity e um Bacharelado em Ciência da Computação. Minha paixão por jogos, linguística e tecnologia impulsiona minha trajetória profissional.",
        profile_description2: "Entusiasta de Metodologias Ágeis, também sou poliglota e aprecio aprender novas linguagens naturais e de programação. Essa combinação de habilidades e interesses me posiciona de maneira única para contribuir com projetos inovadores que exijam proficiência técnica e resolução criativa de problemas.",
        professional_exp: "Experiência Profissional",
        jobs: [{
            job_position: "Engenheiro de Jogos Unity",
            company_name: "Fortis Games",
            location: "Brasília, Brasil",
            period: "Out 2023 - Atual",
            descriptions: [{
                0: "Engenheiro de Jogos responsável por recursos LiveOps para todos os novos jogos da Fortis.",
                1: "Como a Fortis é uma empresa nova, estamos criando nossos próprios SDKs/Ferramentas para oferecer suporte a todos os nossos jogos em desenvolvimento. Minha responsabilidade é implementar nos jogos o que nossa equipe de backend está desenvolvendo.",
                2: "Criei SDKs/Ferramentas para suportar jogos em desenvolvimento.",
                3: "Implementei recursos como sistema de Intersticiais, Loja In-game/IAP, Passe de Batalha, Recompensas Diárias e mais, utilizando Unity3D (C#)."
            }]
        },
        {
            job_position: "Engenheiro de Jogos Unity",
            company_name: "Azumo LLC",
            location: "Brasília, Brasil",
            period: "Ago 2023 - Nov 2023",
            descriptions: [{
                0: "Consultor contratado para a Zynga no jogo Game of Thrones: Legends.",
                1: "Implementei novos recursos de gameplay e interface do usuário, como melhorias em personagens e construções.",
                2: "Trabalhei em recursos como o FTUE do jogo e na aplicação de assets criados pela equipe de Tech Art.",
                3: "Colaborei lado a lado com Designers e Produtores da Zynga e participei de várias reuniões decisivas sobre o gameplay central do jogo.",
                4: "O jogo foi desenvolvido usando Unity3D (C#) e várias ferramentas e SDKs customizados da Zynga."
            }]
        },
        {
            job_position: "Desenvolvedor de Software Unity e C#",
            company_name: "Instituto ELDORADO",
            location: "Manaus, Brasil",
            period: "Fev 2023 - Out 2023",
            descriptions: [{
                0: "Desenvolvi projetos Unity3D e forneci suporte arquitetural.",
                1: "Reestruturei um jogo educacional embutido projetado para áreas remotas.",
                2: "Desenvolvi aplicativos desktop e móveis utilizando Unity3D, .NET Core e Kotlin."
            }]
        },
        {
            job_position: "Engenheiro de Jogos",
            company_name: "Wildlife Studios",
            location: "São Paulo, Brasil (Remoto)",
            period: "Jun 2022 - Nov 2022",
            descriptions: [{
                0: "Engenheiro de jogos responsável por dar suporte e desenvolver novos recursos para jogos live da Wildlife, tanto no cliente quanto no servidor.",
                1: "Trabalhei no 'Block Craft 3D', um jogo voxel desenvolvido em Cocos2D com mais de 1 milhão de usuários ativos diários.",
                2: "Também trabalhei no 'Suspects', um jogo de dedução social desenvolvido em Unity3D com mais de 250 mil usuários ativos diários.",
                3: "Utilizei frameworks Scrum e Kanban para implementar boas práticas de gerenciamento de equipe, contribuindo para um desenvolvimento mais ágil.",
                4: "As aplicações utilizam Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (backend), Redis e MongoDB."
            }]
        },
        {
            job_position: "Desenvolvedor de Software Unity",
            company_name: "Instituto Sidia de Ciência e Tecnologia",
            location: "Manaus, Brasil",
            period: "Abr 2021 - Jun 2022",
            descriptions: [{
                0: "Equipe de Desenvolvimento de Realidade Aumentada no Instituto Sidia. Responsável por desenvolver projetos confidenciais para vários clientes, incluindo a Samsung, ao criar aplicativos para óculos de realidade virtual e aumentada.",
                1: "Além de usar Unity Engine para AR/VR, utilizei C++ para atender às demandas do Samsung Watch3 (Tizen OS).",
                2: "Utilizei a metodologia Scrum para implementar boas práticas de gerenciamento de equipe, contribuindo para um desenvolvimento mais ágil.",
                3: "As aplicações utilizam Unity3D, C++, Mixed Reality Toolkit da Microsoft, SQL, Confluence e Jenkins, entre outras tecnologias."
            }]
        },
        {
            job_position: "Desenvolvedor Fullstack",
            company_name: "Grupo ICTS",
            location: "Brasília, Brasil",
            period: "Ago 2020 - Mar 2021",
            descriptions: [{
                0: "Equipe de Desenvolvimento da área de seguros de vida do SICOOB. Responsável pelo desenvolvimento de novos recursos para os diversos aplicativos relacionados ao seguro de vida.",
                1: "Utilizei o framework ágil Scrum para implementar boas práticas de gerenciamento de equipe, contribuindo para um desenvolvimento mais ágil.",
                2: "Os aplicativos de seguro de vida utilizam Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven e Jenkins como tecnologias e frameworks."
            }]
        },
        {
            job_position: "Desenvolvedor Fullstack",
            company_name: "Globalweb Outsourcing",
            location: "Brasília, Brasil",
            period: "Fev 2020 - Ago 2021",
            descriptions: [{
                0: "Equipe de Desenvolvimento na Caixa Econômica Federal, focado na solução web da loteria do Brasil. O site já tinha mais de dois milhões de pessoas registradas, correspondendo a 80 milhões de apostas.",
                1: "No front-end, implementei novos recursos no site, como notificações, reformulação do carrinho de compras e a adição de uma nova modalidade de apostas.",
                2: "Implementei também recursos para um aplicativo web voltado para gerentes, permitindo visualizar estatísticas e análises da aplicação de loteria.",
                3: "No back-end, trabalhei na integração do backend das loterias online com a API de pagamentos do Mercado Pago, um serviço similar ao PayPal.",
                4: "Participei da definição do esquema de banco de dados da aplicação (banco de dados DB2).",
                5: "Integrei a aplicação online às filas IBM MQ com um sistema legado de 30 anos desenvolvido em COBOL.",
                6: "A aplicação web de loteria utiliza Java, EJB, IBM MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame e OpenJPA como tecnologias."
            }]
        },
        {
            job_position: "Assistente de Pesquisa - Desenvolvedor Fullstack",
            company_name: "Instituto Brasileiro de Informação em Ciência e Tecnologia - IBICT",
            location: "Brasília, Brasil",
            period: "Set 2019 - Fev 2020",
            descriptions: [{
                0: "No Instituto Brasileiro de Informação em Ciência e Tecnologia, trabalhei analisando a arquitetura de informação do VISAO (Sistema de Observatório Aberto para Visualização de Informação), hospedado em visao.ibict.br.",
                1: "Meu principal objetivo foi propor um modelo de migração de dados para a infraestrutura da aplicação e comprovar sua viabilidade. Também identifiquei falhas de persistência de dados no banco de dados do VISAO.",
                2: "O projeto VISAO utilizava HTML, JavaScript, Angular, PHP e MySQL como ferramentas de desenvolvimento."
            }]
        }],
        education_title: "Educação",
        educations: [{
            course: "Pós-graduação em Desenvolvimento de Sistemas com Java",
            college: "Faculdade Unyleya",
            location: "Brasília, Brasil",
            period: "Mar. 2019 - Nov. 2019"
        },
        {
            course: "Bacharelado em Ciência da Computação",
            college: "Universidade de Brasília - UnB",
            location: "Brasília, Brasil",
            period: "Ago. 2014 - Dez. 2018"
        }],
        language_title: "Idiomas",
        portuguese: "Português (Nativo)", 
        english: "Inglês (Avançado)",
        italian: "Italiano (Avançado)",
        french: "Francês (Básico)",
    }
};

export default translation;
