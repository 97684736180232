const translation = {
    translation: {
        under_construction: "In costruzione!",
        home: {
            title: "Home",
            description: "Benvenuto nel mio sito/blog! Questo è uno spazio dove condivido conoscenze e curiosità sulle mie passioni: codice, giochi e linguistica!",
        },
        pt_BR: "pt_BR",
        en_US: "en", 
        it_IT: "it",
        job_description: "Sviluppatore Software",
        about: "Chi Sono",
        blog: {
            title: "Blog",
            viewOnGithub: "Visualizza su Github",
            posts: [
                {
                    id: 1,
                    title: "Dungeon Command: Esplorando il Pattern di Progettazione Command",
                    description: "Dungeon Command è un gioco rompicapo 3D sviluppato come esempio di applicazione del Pattern di Progettazione Command, in cui il giocatore controlla un personaggio su una plancia piena di tessere, barriere e oggetti da collezionare. L'obiettivo è raccogliere tutti gli oggetti sparsi sulla plancia affrontando ostacoli che richiedono rotazioni strategiche della plancia per regolare le barriere e sbloccare nuovi percorsi.",
                    image: "/DungeonCommand.png",
                    unityPath: "/unity-build/CommandPattern/index.html",
                    gitHubUrl: "https://github.com/henritar/UnityCommandPatternExample",
                    content: [
                        {
                            sectionTitle: "Panoramica del Post",
                            paragraphs: [
                                "In questo articolo esploreremo come un gioco puzzle 3D – Dungeon Command – implementa il Command Design Pattern per gestire il movimento del giocatore, la rotazione del tabellone e altre azioni cruciali.",
                                "L'obiettivo è mostrare come il Command Pattern offra chiarezza, flessibilità e un semplice meccanismo di undo/redo in un gioco in cui ogni mossa è determinante!"
                            ]
                        },
                        {
                            sectionTitle: "Cos'è Dungeon Command?",
                            paragraphs: [
                                "Dungeon Command è un gioco puzzle in 3D in cui guidi un personaggio su un tabellone composto da caselle (tiles).",
                                "Ecco alcuni punti chiave:"
                            ],
                            bulletPoints: [
                                "Meccanica Principale: Muovere il personaggio nel tabellone e ruotare l'intero scenario.",
                                "Obiettivo: Raccogliere tutti gli oggetti presenti nel livello, evitando barriere e risolvendo puzzle spaziali.",
                                "Caratteristica Unica: La capacità di ruotare l'intero tabellone, cambiando radicalmente il layout e aprendo nuovi percorsi."
                            ]
                        },
                        {
                            sectionTitle: "Perché Usare il Command Pattern?",
                            paragraphs: [
                                "Il Command Pattern è eccellente per separare il 'cosa fare' (i comandi) dal 'quando farlo' (l'invoker).",
                                "Nei giochi, risulta utile perché:"
                            ],
                            bulletPoints: [
                                "Separazione Chiara dei Compiti: Il trattamento degli input non è mescolato con la logica di azione di gioco.",
                                "Undo/Redo Semplice: Ogni comando sa come annullare se stesso, semplificando la gestione dello storico delle azioni.",
                                "Supporto per Operazioni Asincrone: Alcune azioni richiedono tempo (come l'animazione di rotazione del tabellone), e il Command Pattern le gestisce comodamente.",
                                "Estendibilità: È facile aggiungere nuovi comandi senza modificare la struttura generale.",
                                "Testabilità: Poiché ogni comando è indipendente, può essere testato in isolamento."
                            ]
                        },
                        {
                            sectionTitle: "Struttura dell'Implementazione",
                            subSections: [
                                {
                                    subSectionTitle: "Interfacce di Comando",
                                    paragraphs: [
                                        "Il gioco definisce un'interfaccia per i comandi, permettendo l'esecuzione e l'annullamento."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public interface ICommand {\n    bool Execute();\n    void Undo();\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "Comandi Concreti",
                                    subSections: [
                                        {
                                            subSectionTitle: "MoveCommand",
                                            paragraphs: [
                                                "Ruolo: Muove il personaggio nelle quattro direzioni cardinali.",
                                                "Punti Chiave:"
                                            ],
                                            bulletPoints: [
                                                "Memorizza la posizione precedente per permettere il ripristino tramite undo.",
                                                "Controlla collisioni con barriere e confini del tabellone.",
                                                "Attiva le caselle quando il giocatore ci cammina sopra e raccoglie gli oggetti presenti."
                                            ],
                                            usage: "Innescato quando il giocatore preme W, A, S, D."
                                        },
                                        {
                                            subSectionTitle: "RotateBoardCommand",
                                            paragraphs: [
                                                "Ruolo: Ruota il tabellone (in senso orario o antiorario).",
                                                "Punti Chiave:"
                                            ],
                                            bulletPoints: [
                                                "Implementa IAsyncCommand per gestire l'animazione di rotazione.",
                                                "Impedisce ulteriori rotazioni mentre una è in corso.",
                                                "Esegue un undo automatico se la rotazione genera uno stato non valido.",
                                                "Ricalcola le barriere dopo la rotazione."
                                            ],
                                            usage: "Innescato premendo Q (antiorario) o E (orario)."
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            sectionTitle: "Come Giocare",
                            paragraphs: [
                                "Dungeon Command è controllato utilizzando i seguenti tasti:"
                            ],
                            bulletPoints: [
                                "Movimento: W, A, S, D",
                                "Rotazione del Tabellone: Q (antiorario) e E (orario)",
                                "Annulla Azione: Z",
                                "Reset del Livello: R"
                            ]
                        },
                        {
                            sectionTitle: "Flusso di Gioco",
                            paragraphs: [
                                "Il gameplay si basa sui seguenti passaggi:"
                            ],
                            bulletPoints: [
                                "Muoviti sul tabellone con WASD.",
                                "Calpestando una casella, questa si attiva; alcune barriere potrebbero apparire o sparire.",
                                "Ruota il tabellone con Q o E per aprire nuovi percorsi.",
                                "Raccogli tutti gli oggetti per completare il livello.",
                                "Se commetti un errore, premi Z per annullare l'ultima azione.",
                                "Premi R per resettare il livello, se vuoi ricominciare da capo."
                            ]
                        },
                        {
                            sectionTitle: "Dettagli Tecnici",
                            subSections: [
                                {
                                    subSectionTitle: "Storico dei Comandi e Undo",
                                    paragraphs: [
                                        "I comandi eseguiti vengono memorizzati in una Stack<ICommand>.",
                                        "Per l'undo, l'ultimo comando viene rimosso dallo stack e ne viene eseguito Undo().",
                                        "I comandi asincroni gestiscono l'undo in modo particolare, attendendo il completamento dell'animazione in corso:"
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "private IEnumerator ProcessUndoMove() {\n    isUndoing = true;\n    ICommand lastCommand = commandHistory.Pop();\n    if (lastCommand is IAsyncCommand asyncCommand) {\n        asyncCommand.Undo();\n        while (!asyncCommand.IsCompleted) {\n            yield return null;\n        }\n    } else {\n        lastCommand.Undo();\n    }\n    isUndoing = false;\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "Rotazione del Tabellone",
                                    paragraphs: [
                                        "Quando il tabellone viene ruotato:"
                                    ],
                                    bulletPoints: [
                                        "Le posizioni delle caselle vengono ricalcolate in base alla direzione della rotazione.",
                                        "Le barriere cambiano orientamento (orizzontale ↔ verticale).",
                                        "La posizione del giocatore viene regolata sul centro della casella più vicina.",
                                        "Le barriere vengono aggiornate in base alla nuova configurazione."
                                    ]
                                }
                            ]
                        },
                        {
                            sectionTitle: "Conclusione",
                            paragraphs: [
                                "Il Command Pattern fornisce un modo strutturato ed estensibile per gestire le azioni in Dungeon Command.",
                                "Permette un'espansione semplice, una maggiore manutenibilità e un sistema di undo integrato."
                            ]
                        },
                        {
                            sectionTitle: "Ti è piaciuto il Command Pattern?",
                            paragraphs: [
                                "Se hai trovato interessante il Command Design Pattern, resta sintonizzato per i prossimi post!",
                                "Condividerò altri esempi di pattern di design nei videogiochi, per mostrare quanto possano portare ordine e organizzazione nello sviluppo."
                            ]
                        },
                        {
                            sectionTitle: "Grazie per aver letto!",
                            paragraphs: [
                                "Se hai domande o suggerimenti, lasciaci un commento: ci farà piacere discuterne insieme."
                            ]
                        }
                    ]
                },
                {
                    id: 2,
                    title: "Tile Matching Game: Implementazione del Pattern Strategy negli Obiettivi",
                    description: "Questo post esplora come il Pattern Strategy viene applicato nel sistema degli Obiettivi del Tile Matching Game. Permette diversi tipi di obiettivi senza modificare la logica di base del gioco, garantendo flessibilità e scalabilità.",
                    image: "/TileMatchingGame.png",
                    unityPath: "/unity-build/StrategyPattern/index.html",
                    gitHubUrl: "https://github.com/henritar/TileMatchingGame",
                    content: [
                        {
                            sectionTitle: "Panoramica del Post",
                            paragraphs: [
                                "Questo post spiega come il Pattern Strategy viene utilizzato nel Tile Matching Game per gestire dinamicamente diversi obiettivi di gioco.",
                                "Il Pattern Strategy permette al gioco di definire molteplici tipi di obiettivi senza modificare la logica esistente, rendendo più facile aggiungere nuove sfide in futuro."
                            ]
                        },
                        {
                            sectionTitle: "Cos'è il Pattern Strategy?",
                            paragraphs: [
                                "Il Pattern Strategy definisce una famiglia di algoritmi, incapsula ciascuno di essi e li rende intercambiabili.",
                                "Permette al gioco di selezionare la strategia appropriata (tipo di obiettivo) durante l'esecuzione senza modificare la logica principale del gioco."
                            ]
                        },
                        {
                            sectionTitle: "Come Viene Utilizzato nel Tile Matching Game?",
                            paragraphs: [
                                "Il sistema degli obiettivi del gioco è costruito utilizzando il Pattern Strategy, permettendo diversi tipi di obiettivi:",
                                "Invece di codificare rigidamente diversi comportamenti degli obiettivi, il gioco definisce un'interfaccia IGoal, implementata da diverse classi di obiettivo."
                            ],
                            bulletPoints: [
                                "ObiettivoRaccoltaTessereColore: Richiede al giocatore di rimuovere un numero specifico di tessere di un certo colore.",
                                "ObiettivoRaccoltaPunti: Il giocatore deve raggiungere un punteggio obiettivo.",
                                "ObiettivoMosseMassime: Il giocatore deve completare il livello entro un numero limitato di mosse."
                            ]
                        },
                        {
                            sectionTitle: "Implementazione del Pattern Strategy nel Codice",
                            paragraphs: [
                                "L'interfaccia IGoal definisce il contratto per tutti i tipi di obiettivo. Ogni classe concreta di obiettivo implementa questa interfaccia, garantendo una struttura standardizzata."
                            ],
                            code: [
                                {
                                    language: "csharp",
                                    content: "public interface IGoal<T> {\n    bool IsGoalCompleted();\n    bool HasFailedGoal();\n    string GetDescription();\n    void UpdateProgress(T progressData);\n    string GetProgress();\n    void SetupGoal(GoalSetup[] setupData);\n}"
                                }
                            ]
                        },
                        {
                            sectionTitle: "Strategie Concrete di Obiettivo",
                            subSections: [
                                {
                                    subSectionTitle: "CollectColorTilesGoal",
                                    paragraphs: [
                                        "Questo obiettivo richiede al giocatore di abbinare un numero specifico di tessere di un determinato colore.",
                                        "Tiene traccia del numero di tessere rimosse e verifica se il target è stato raggiunto."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class CollectColorTilesGoal : IGoal<Tile> {\n    private readonly Dictionary<TileColor, int> _amountByColor = new Dictionary<TileColor, int>();\n    private readonly Dictionary<TileColor, int> _currentByColor = new Dictionary<TileColor, int>();\n    public GoalsEnum Goal => GoalsEnum.ColorTilesGoal;\n\n    public string GetDescription() {\n        return \"Devi ottenere:\\n \" + string.Join(\"\\n\", _amountByColor.Select(par => $\"{par.Key} {par.Value} tessere\"));\n    }\n\n    public string GetProgress() {\n        return string.Join(\"\\n\", _amountByColor.Select(par => $\"{_currentByColor[par.Key]}/{par.Value} {par.Key}\\n\"));\n    }\n\n    public bool HasFailedGoal() {\n        return false;\n    }\n\n    public bool IsGoalCompleted() {\n        return _currentByColor.All(par => par.Value >= _amountByColor[par.Key]);\n    }\n\n    public void SetupGoal(Level.GoalSetup[] setupData) {\n        _currentByColor.Clear();\n        _amountByColor.Clear();\n        foreach (var colorGoal in setupData.Where(data => data.goalEnum == GoalsEnum.ColorTilesGoal)) {\n            _amountByColor[colorGoal.tileColor] = colorGoal.tileQuantity;\n            _currentByColor[colorGoal.tileColor] = 0;\n        }\n    }\n\n    public void UpdateProgress(Tile progressData) {\n        _currentByColor[tile.TileData.Color]++;\n    }\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "CollectTilesPointsGoal",
                                    paragraphs: [
                                        "Questo obiettivo richiede al giocatore di raggiungere un punteggio specifico abbinando tessere.",
                                        "Tiene traccia del punteggio accumulato e determina se il target è stato raggiunto."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class CollectTilesPointsGoal : IGoal<int> {\n    private int _amountToCollect;\n    private int _currentScore;\n\n    public GoalsEnum Goal => GoalsEnum.TotalPointsGoal;\n\n    public CollectTilesPointsGoal() {\n        _currentScore = 0;\n    }\n\n    public bool IsGoalCompleted() {\n        return _currentScore >= _amountToCollect;\n    }\n\n    public string GetDescription() {\n        return $\"Raccogli {_amountToCollect} punti!\";\n    }\n\n    public string GetProgress() {\n        return $\"{_currentScore}/{_amountToCollect}\";\n    }\n\n    public void UpdateProgress(int progressData) {\n        _currentScore = score;\n    }\n\n    public bool HasFailedGoal() {\n        return false;\n    }\n\n    public void SetupGoal(GoalSetup[] setupData) {\n        var pointsGoal = setupData.First(data => data.goalEnum == GoalsEnum.TotalPointsGoal);\n        _amountToCollect = pointsGoal.maxPoints;\n        _currentScore = 0;\n    }\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "MaxMovesGoal",
                                    paragraphs: [
                                        "Questo obiettivo richiede al giocatore di completare il livello in un numero specifico di mosse.",
                                        "Tiene traccia del numero di mosse effettuate e fallisce se il giocatore supera il limite."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class MaxMovesGoal : IGoal<int> {\n    private int _maxMoves;\n    private int _totalMoves;\n    public GoalsEnum Goal => GoalsEnum.MaxMovesGoal;\n\n    public MaxMovesGoal() {\n        _totalMoves = 0;\n        _maxMoves = int.MaxValue;\n    }\n\n    public string GetDescription() {\n        return $\"Completa questo livello con meno di {_maxMoves} mosse!\";\n    }\n\n    public string GetProgress() {\n        return $\"{_maxMoves - _totalMoves} rimaste!\";\n    }\n\n    public bool HasFailedGoal() {\n        return _totalMoves >= _maxMoves;\n    }\n\n    public bool IsGoalCompleted() {\n        return _maxMoves >= _totalMoves;\n    }\n\n    public void SetupGoal(GoalSetup[] setupData) {\n        var maxMovesGoal = setupData.First(data => data.goalEnum == GoalsEnum.MaxMovesGoal);\n        _maxMoves = maxMovesGoal.maxPoints;\n        _totalMoves = 0;\n    }\n\n    public void UpdateProgress(int progressData) {\n        _totalMoves++;\n    }\n}"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            sectionTitle: "Perché Usare il Pattern Strategy Qui?",
                            paragraphs: [
                                "Il Pattern Strategy offre diversi vantaggi per il sistema degli Obiettivi:",
                                "Rende facile introdurre nuovi tipi di obiettivo senza modificare la logica esistente.",
                                "Il gioco può passare dinamicamente tra diversi obiettivi durante l'esecuzione, permettendo design dei livelli più flessibili."
                            ],
                            bulletPoints: [
                                "Incapsulamento: Ogni tipo di obiettivo è una classe separata, rendendo il codice più facile da mantenere.",
                                "Estensibilità: Nuovi obiettivi possono essere aggiunti senza modificare la logica principale del gioco.",
                                "Modularità: Ogni obiettivo si comporta indipendentemente, rendendo facile testare e debuggare."
                            ]
                        },
                        {
                            sectionTitle: "Conclusione",
                            paragraphs: [
                                "Il Pattern Strategy è un modo potente per gestire diversi obiettivi di gioco in modo scalabile e manutenibile.",
                                "Utilizzando questo pattern, il Tile Matching Game garantisce flessibilità nella definizione degli obiettivi, rendendo il gioco più facile da espandere in futuro."
                            ]
                        },
                        {
                            sectionTitle: "Ti è Piaciuto il Pattern Strategy?",
                            paragraphs: [
                                "Se hai trovato interessante il Pattern Strategy, resta sintonizzato per i prossimi post!",
                                "Condividerò altri esempi di pattern di design nei videogiochi per mostrare come possono portare ordine e organizzazione nello sviluppo."
                            ]
                        },
                        {
                            sectionTitle: "Grazie per aver Letto!",
                            paragraphs: [
                                "Se hai domande o suggerimenti, lascia un commento: mi piacerebbe discuterne con te."
                            ]
                        }   
                    ]
                },
            ]
        },
        post: {
            notFound: "Post non trovato.",
            error: "Si è verificato un errore durante il caricamento del post.",
            readMore: "Leggi di più",
            goBack: "Torna al blog",
        },          
        contact: "Contatto",
        city_country: "Manaus, Brasile",
        profile: "Profilo",
        profile_description1: "Sono uno sviluppatore di giochi italo-brasiliano con una certificazione Unity Professional e una laurea in Informatica. La mia passione per i giochi, la linguistica e la tecnologia guida il mio percorso professionale.",
        profile_description2: "Appassionato di metodologie Agile, sono anche un poliglotta che ama imparare nuove lingue naturali e di programmazione. Questo mix di competenze e interessi mi consente di contribuire a progetti innovativi che richiedono competenze tecniche e capacità di problem-solving creativo.",
        professional_exp: "Esperienza Professionale",
        jobs: [
            {
                job_position: "Ingegnere di Giochi Unity",
                company_name: "Fortis Games",
                location: "Brasilia, Brasile",
                period: "Ott 2023 - Presente",
                descriptions: [
                    {
                        0: "Ingegnere di giochi responsabile delle funzionalità LiveOps per tutti i nuovi giochi di Fortis.",
                        1: "Essendo Fortis una compagnia relativamente nuova, stiamo creando i nostri SDK/strumenti per supportare tutti i nostri giochi in sviluppo, e sono responsabile di implementarli nei giochi sviluppati dal nostro team backend.",
                        2: "Creati SDK/strumenti per supportare i giochi in sviluppo.",
                        3: "Implementate funzionalità come sistema di interstitial, negozio in-game/IAP, Battlepass, premi giornalieri e altro utilizzando Unity3D (C#)."
                    }
                ]
            },
            {
                job_position: "Ingegnere di Giochi Unity",
                company_name: "Azumo LLC",
                location: "Brasilia, Brasile",
                period: "Ago 2023 - Nov 2023",
                descriptions: [
                    {
                        0: "Consulente per Zynga sul gioco Game of Thrones: Legends.",
                        1: "Implementato nuove funzionalità di gameplay e UI come aggiornamento di personaggi e edifici.",
                        2: "Lavorato su funzionalità come aggiornamento di personaggi e edifici, implementazione del FTUE del gioco e applicazione di asset creati dal team Tech Art.",
                        3: "Collaborato con designer e produttori di Zynga partecipando a molte decisioni sul gameplay principale del gioco.",
                        4: "Il gioco è stato sviluppato utilizzando Unity3D (C#) e molti SDK/strumenti personalizzati di Zynga."
                    }
                ]
            },
            {
                job_position: "Sviluppatore Software Unity & C#",
                company_name: "ELDORADO Institute",
                location: "Manaus, Brasile",
                period: "Feb 2023 - Ott 2023",
                descriptions: [
                    {
                        0: "Ho lavorato al refactoring di un gioco educativo integrato a Unity3D, originariamente sviluppato in JavaScript. Il gioco è progettato per funzionare su hardware con limitazioni di memoria e dimensioni, consentendo di portarlo in luoghi difficili da raggiungere (come villaggi nella foresta amazzonica).",
                        1: "Oltre a Unity3D, sono stato responsabile dello sviluppo e del supporto di applicazioni desktop in .NET Core e mobili in Kotlin.",
                        2: "Ho lavorato allo sviluppo di un software per desktop e dispositivi mobili per la lettura e scrittura di dati su dispositivi di misurazione elettrica della Nansen, uno dei produttori di contatori più tradizionali al mondo.",
                        3: "Le applicazioni utilizzano Unity3D (C#), .NET Core (WPF), Kotlin, tra altre tecnologie."
                    }
                ]
            },
            {
                job_position: "Ingegnere di Gioco",
                company_name: "Wildlife Studios",
                location: "Sao Paulo, Brasile (Remoto)",
                period: "Jun 2022 - Nov 2022",
                descriptions: [
                    {
                        0: "Ingegnere di gioco responsabile del supporto e dello sviluppo di nuove funzionalità per i giochi Wildlife live sia sul lato client che su quello server",
                        1: "Ho lavorato in Cocos2D 'Block Craft 3D', un gioco voxel da +1,0 milioni di utenti attivi al giorno che consiste nel costruire qualsiasi cosa il giocatore desideri",
                        2: "Ho lavorato anche in Unity3D 'Suspects', un gioco di deduzione sociale da +250k utenti attivi al giorno con chat vocale integrata e un sistema di progressione adeguato",
                        3: "Utilizzo dei framework Scrum e Kanban per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile",
                        4: "Le applicazioni utilizzano come tecnologie Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (Backend), Redis e MongoDB"
                    }
                ]
            },
            {
                job_position: "Sviluppatore Unity",
                company_name: "Sidia Istituto di Scienza e Tecnologia",
                location: "Manaus, Brasile",
                period: "Apr 2021 - Jun 2022",
                descriptions: [
                    {
                        0: "Team di sviluppo presso il team di realtà aumentata del Sidia Institute of Science and Technology. Responsabile dello sviluppo di alcuni progetti riservati per vari clienti, come Samsung, sviluppando applicazioni per occhiali di realtà virtuale e realtà aumentata",
                        1: "Oltre a Unity Engine per AR/VR, è stato utilizzato anche C++ per soddisfare le richieste di Samsung Watch3 (Tizen OS)",
                        2: "Uso del framework Scrum per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile",
                        3: "Le applicazioni utilizzano, tra le altre tecnologie riservate, Unity3D, C++, Microsoft Mixed Reality Toolkit, SQL, Confluence e Jenkins come tecnologie"
                    }
                ]
            },
            {
                job_position: "Sviluppatore software Fullstack",
                company_name: "Grupo ICTS",
                location: "Brasilia, Brasile",
                period: "Ago 2020 - Mar 2021",
                descriptions: [
                    {
                        0: "Team Dev presso il team assicurazioni vita della SICOOB. È responsabile dello sviluppo di nuove funzionalità per le varie applicazioni relative alle assicurazioni sulla vita.",
                        1: "Utilizzazione del framework di sviluppo agile Scrum per implementare buone pratiche di gestione del team che contribuiscono a uno sviluppo più agile.",
                        2: "L'applicazione di assicurazione sulla vita utilizza come tecnologie e framework Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven e Jenkins."
                    }
                ]
            },
            {
                job_position: "Sviluppatore software Fullstack",
                company_name: "Globalweb Outsourcing",
                location: "Brasilia, Brasile",
                period: "Feb 2020 - Ago 2021",
                descriptions: [
                    {
                        0: "Dev Team della Caixa Econômica Federal per la soluzione web della lotteria brasiliana. Il sito web aveva circa un anno di vita e aveva già più di due milioni di persone registrate, il che corrisponde a 80 milioni di scommesse alla lotteria",
                        1: "Nel frontend, ho lavorato all'implementazione di nuove funzionalità del sito, come le notifiche, il refactor del carrello e l'aggiunta di nuove modalità di scommessa",
                        2: "Ho lavorato anche all'implementazione di funzionalità per un'applicazione web per i manager, in modo che potessero vedere statistiche e analisi dall'applicazione della lotteria",
                        3: "Nel backend, ho lavorato all'integrazione del backend delle lotterie online con le API di pagamento di Mercado Pago, un servizio simile a PayPal",
                        4: "Ho lavorato alla definizione dello schema del database dell'applicazione (database DB2)",
                        5: "Ho lavorato anche all'integrazione dell'applicazione online attraverso le code MQ di IBM con un sistema di 30 anni fa sviluppato in COBOL",
                        6: "L'applicazione web della lotteria utilizza come tecnologie Java, EJB, Ibm MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame e OpenJPA"
                    }
                ]
            },
            {
                job_position: "Assistente di Ricerca - Sviluppatore Fullstack",
                company_name: "Istituto Brasiliano di Informazione in Scienza e Tecnologia - IBICT",
                location: "Brasilia, Brasile",
                period: "Set 2019 - Feb 2020",
                descriptions: [
                    {
                        0: "Presso l'Istituto brasiliano di scienza e tecnologia dell'informazione ho lavorato analizzando l'architettura dell'informazione di VISAO (Open Observatory System for Information Visualization), ospitato su visao.ibict.br.",
                        1: "Il mio obiettivo principale era proporre un modello di migrazione dei dati per l'infrastruttura dell'applicazione e dimostrarne la fattibilità. Mi sono anche occupato dei problemi di persistenza dei dati nel database VISAO.",
                        2: "Il progetto VISAO ha utilizzato HTML, JavaScript, Angular, PHP e MySQL come strumenti di sviluppo."
                    }
                ]
            }
        ],
        education_title: "Education",
        educations: [
            {
                course: "Post-laurea in Sviluppo di Sistemi Con Java",
                college: "Faculdade Unyleya",
                location: "Brasilia, Brasile",
                period: "Mar 2019 - Nov 2019"
            },
            {
                course: "Laurea Triennale in Informatica",
                college: "Università di Brasilia - UnB",
                location: "Brasilia, Brasile",
                period: "Ago 2014 - Dic 2018"
            }
        ],
        language_title: "Lingue",
        portuguese: "Portoghese (Nativo)", 
        english: "Inglese (Avanzato)",
        italian: "Italiano (Avanzato)",
        french: "Francese (Basico)"
    }
};

export default translation;