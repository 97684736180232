const translation = {
    translation: {
        under_construction: "Under Construction!",
        home: {
            title: "Home",
            description: "Welcome to my website/blog! This is a place where I share knowledge and curiosities about my passions: code, games and linguistics!",
        },
        pt_BR: "pt_BR",
        en_US: "en",
        it_IT: "it",
        job_description: "Software Developer",
        about: "About",
        blog: {
            title: "Blog",
            viewOnGithub: "View on Github",
            posts: [
                {
                    id: 1,
                    title: "Dungeon Command: Exploring the Command Design Pattern",
                    description: "Dungeon Command is a 3D puzzle game developed as an example of the application of the Command Design Pattern, where the player controls a character on a board filled with tiles, barriers, and collectibles. The goal is to collect all the items scattered across the board while facing obstacles that require strategic board rotations to adjust the barriers and unlock new paths.",
                    image: "/DungeonCommand.png",
                    unityPath: "/unity-build/CommandPattern/index.html",
                    gitHubUrl: "https://github.com/henritar/UnityCommandPatternExample",
                    content: [
                        {
                            sectionTitle: "Post Overview",
                            paragraphs: [
                                "In this blog post, we'll explore how a 3D puzzle game—Dungeon Command—implements the Command Design Pattern to manage player movement, board rotation, and other critical actions.",
                                "The goal is to show how the Command Pattern brings clarity, flexibility, and an easy undo mechanism (Undo/Redo) to a game where every move matters!"
                            ]
                        },
                        {
                            sectionTitle: "What Is Dungeon Command?",
                            paragraphs: [
                                "Dungeon Command is a 3D puzzle game in which you guide a character through a tile-based environment.",
                                "Here are some key points:"
                            ],
                            bulletPoints: [
                                "Core Mechanic: Move the player character across the board and rotate the entire scene.",
                                "Objective: Collect all items in the level while navigating around barriers and solving spatial puzzles.",
                                "Unique Feature: The ability to rotate the entire board, changing the layout completely and opening new paths."
                            ]
                        },
                        {
                            sectionTitle: "Why Use the Command Pattern?",
                            paragraphs: [
                                "The Command Pattern is excellent for separating \"what to do\" (the commands) from \"when to do it\" (the invoker).",
                                "In games, it helps in several ways:"
                            ],
                            bulletPoints: [
                                "Clear Separation of Concerns: Input handling is not mixed with game action logic.",
                                "Easier Undo/Redo: Each command knows how to undo itself, simplifying history management.",
                                "Support for Asynchronous Operations: Some actions take time (like a board rotation animation), and the Command Pattern can handle that elegantly.",
                                "Extensibility: It's easy to add new commands without changing the overall structure.",
                                "Testability: Since each command is independent, you can test them in isolation."
                            ]
                        },
                        {
                            sectionTitle: "Command Pattern Implementation",
                            paragraphs: [
                                "The game defines an interface for commands, allowing execution and undo functionality.",
                                "Here's an example of the ICommand interface used in the game:"
                            ],
                            code: [
                                {
                                    language: "csharp",
                                    content: "public interface ICommand {\n    bool Execute();\n    void Undo();\n}"
                                }
                            ]
                        },
                        {
                            sectionTitle: "Concrete Commands",
                            subSections: [
                            {
                                subSectionTitle: "MoveCommand",
                                paragraphs: [
                                    "Role: Moves the player in the four cardinal directions.",
                                    "Key Points:"
                                ],
                                bulletPoints: [
                                    "Stores the previous position for undo functionality.",
                                    "Checks for collisions with barriers and board boundaries.",
                                    "Activates tiles when the player steps on them and collects items when encountered."
                                ],
                                usage: "Triggered when the player presses W, A, S, D."
                            },
                            {
                                subSectionTitle: "RotateBoardCommand",
                                paragraphs: [
                                    "Role: Rotates the board (clockwise or counterclockwise).",
                                    "Key Points:"
                                ],
                                bulletPoints: [
                                    "Implements IAsyncCommand to manage rotation animations.",
                                    "Prevents new rotations while one is in progress.",
                                    "Performs an automatic undo if the rotation leads to an invalid state.",
                                    "Recalculates barriers after the rotation."
                                ],
                                usage: "Triggered by Q (counterclockwise) or E (clockwise)."
                            }
                            ]
                        },
                        {
                            sectionTitle: "PlayerController as the Invoker",
                            paragraphs: [
                                "The PlayerController is the \"brain\" that reacts to user input and calls the appropriate commands.",
                                "Executed commands are stored in a stack to support the undo feature."
                            ],
                            code: [
                                {
                                    language: "csharp",
                                    content: "public void ExecuteCommand(ICommand command) {\n    if (command.Execute()) {\n        commandHistory.Push(command);\n    }\n    CollectableManager.Instance.CheckLevelComplete();\n}"
                                }
                            ]
                        },
                        {
                            sectionTitle: "How to Play",
                            paragraphs: [
                                "Dungeon Command is controlled using the following keys:"
                            ],
                            bulletPoints: [
                                "Movement: W, A, S, D",
                                "Board Rotation: Q (counterclockwise) and E (clockwise)",
                                "Undo Last Action: Z",
                                "Reset Level: R"
                            ]
                        },
                        {
                            sectionTitle: "Game Flow",
                            paragraphs: [
                                "The gameplay loop consists of the following steps:"
                            ],
                            bulletPoints: [
                                "Move around the board with WASD.",
                                "Stepping on a tile activates it; some barriers may appear or disappear.",
                                "Rotate the board with Q or E if you need new pathways.",
                                "Collect all items to finish the level.",
                                "If you make a mistake, press Z to undo.",
                                "Press R to reset the level if you want to start over."
                            ]
                        },
                        {
                            sectionTitle: "Technical Details",
                            subSections: [
                                {
                                    subSectionTitle: "Command History and Undo",
                                    paragraphs: [
                                        "Executed commands are stored in a Stack<ICommand>.",
                                        "On undo, the most recent command is popped, and its Undo() method is called.",
                                        "Asynchronous commands handle undo differently, awaiting completion of the ongoing animation:"
                                    ],
                                    code: [
                                    {
                                        language: "csharp",
                                        content: "private IEnumerator ProcessUndoMove() {\n    isUndoing = true;\n    ICommand lastCommand = commandHistory.Pop();\n    if (lastCommand is IAsyncCommand asyncCommand) {\n        asyncCommand.Undo();\n        while (!asyncCommand.IsCompleted) {\n            yield return null;\n        }\n    } else {\n        lastCommand.Undo();\n    }\n    isUndoing = false;\n}"
                                    }
                                    ]
                                },
                                {
                                    subSectionTitle: "Board Rotation",
                                    paragraphs: [
                                        "When the board is rotated:"
                                    ],
                                    bulletPoints: [
                                        "Tile positions are recalculated based on the rotation direction.",
                                        "Barriers switch orientation (horizontal ↔ vertical).",
                                        "The player's position is adjusted to the nearest tile center.",
                                        "Barriers are updated according to the new layout."
                                    ]
                                }
                            ]
                        },
                        {
                            sectionTitle: "Conclusion",
                            paragraphs: [
                                "The Command Pattern provides a structured and extensible way to manage actions in Dungeon Command.",
                                "It allows for easy expansion, improved maintainability, and a built-in undo system."
                            ]
                        },
                        {
                            sectionTitle: "Did You Like the Command Pattern?",
                            paragraphs: [
                                "If you found the Command Design Pattern interesting, stay tuned for upcoming posts!",
                                "I will share more examples of design patterns in video games to show how they can bring order and organization to development."
                            ]
                        },
                        {
                            sectionTitle: "Thank You for Reading!",
                            paragraphs: [
                                "If you have any questions or suggestions, leave us a comment—I'd love to discuss them with you."
                            ]
                        }                        
                    ],
                },
                {
                    id: 2,
                    title: "Tile Matching Game: Implementing the Strategy Pattern in Goals",
                    description: "This post explores how the Strategy Pattern is applied in the Goal system of Tile Matching Game. It allows for different goal types without modifying the core game logic, ensuring flexibility and scalability.",
                    image: "/TileMatchingGame.png",
                    unityPath: "/unity-build/StrategyPattern/index.html",
                    gitHubUrl: "https://github.com/henritar/TileMatchingGame",
                    content: [
                        {
                            sectionTitle: "Post Overview",
                            paragraphs: [
                                "This post explains how the Strategy Pattern is used in the Tile Matching Game to handle different game objectives dynamically.",
                                "The Strategy Pattern allows the game to define multiple goal types without modifying existing logic, making it easier to add new challenges in the future."
                            ]
                        },
                        {
                            sectionTitle: "What Is the Strategy Pattern?",
                            paragraphs: [
                                "The Strategy Pattern defines a family of algorithms, encapsulates each one, and makes them interchangeable.",
                                "It allows the game to select the appropriate strategy (goal type) at runtime without modifying the main game logic."
                            ]
                        },
                        {
                            sectionTitle: "How It’s Used in Tile Matching Game?",
                            paragraphs: [
                                "The game’s objective system is built using the Strategy Pattern, allowing different goal types:",
                                "Instead of hardcoding different goal behaviors, the game defines an interface IGoal, implemented by different goal classes." 
                            ],
                            bulletPoints: [
                                "CollectColorTilesGoal: Requires the player to remove a specific number of tiles of a certain color.",
                                "CollectTilesPointsGoal: The player must achieve a target score.",
                                "MaxMovesGoal: The player must complete the level within a limited number of moves."
                            ]
                        },
                        {
                            sectionTitle: "Strategy Pattern Implementation in Code",
                            paragraphs: [
                                "The IGoal interface defines the contract for all goal types. Each concrete goal class implements this interface, ensuring a standardized structure."
                            ],
                            code: [
                                {
                                    language: "csharp",
                                    content: "public interface IGoal<T> {\n    bool IsGoalCompleted();\n    bool HasFailedGoal();\n    string GetDescription();\n    void UpdateProgress(T progressData);\n    string GetProgress();\n    void SetupGoal(GoalSetup[] setupData);\n}"
                                }
                            ]
                        },
                        {
                            sectionTitle: "Concrete Goal Strategies",
                            subSections: [
                                {
                                    subSectionTitle: "CollectColorTilesGoal",
                                    paragraphs: [
                                        "This goal requires the player to match a specific number of tiles of a certain color.",
                                        "It tracks the number of removed tiles and checks if the target has been reached."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class CollectColorTilesGoal : IGoal<Tile> {\n    private readonly Dictionary<TileColor, int> _amountByColor = new Dictionary<TileColor, int>();\n    private readonly Dictionary<TileColor, int> _currentByColor = new Dictionary<TileColor, int>();\n    public GoalsEnum Goal => GoalsEnum.ColorTilesGoal;\n\n    public string GetDescription() {\n        return \"You must get:\\n \" + string.Join(\"\\n\", _amountByColor.Select(par => $\"{par.Key} {par.Value} tiles\"));\n    }\n\n    public string GetProgress() {\n        return string.Join(\"\\n\", _amountByColor.Select(par => $\"{_currentByColor[par.Key]}/{par.Value} {par.Key}\\n\"));\n    }\n\n    public bool HasFailedGoal() {\n        return false;\n    }\n\n    public bool IsGoalCompleted() {\n        return _currentByColor.All(par => par.Value >= _amountByColor[par.Key]);\n    }\n\n    public void SetupGoal(Level.GoalSetup[] setupData) {\n        _currentByColor.Clear();\n        _amountByColor.Clear();\n        foreach (var colorGoal in setupData.Where(data => data.goalEnum == GoalsEnum.ColorTilesGoal)) {\n            _amountByColor[colorGoal.tileColor] = colorGoal.tileQuantity;\n            _currentByColor[colorGoal.tileColor] = 0;\n        }\n    }\n\n    public void UpdateProgress(Tile progressData) {\n        _currentByColor[tile.TileData.Color]++;\n    }\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "CollectTilesPointsGoal",
                                    paragraphs: [
                                        "This goal requires the player to reach a specific score by matching tiles.",
                                        "It tracks the accumulated score and determines whether the target has been reached."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class CollectTilesPointsGoal : IGoal<int> {\n    private int _amountToCollect;\n    private int _currentScore;\n\n    public GoalsEnum Goal => GoalsEnum.TotalPointsGoal;\n\n    public CollectTilesPointsGoal() {\n        _currentScore = 0;\n    }\n\n    public bool IsGoalCompleted() {\n        return _currentScore >= _amountToCollect;\n    }\n\n    public string GetDescription() {\n        return $\"Collect {_amountToCollect} points!\";\n    }\n\n    public string GetProgress() {\n        return $\"{_currentScore}/{_amountToCollect}\";\n    }\n\n    public void UpdateProgress(int progressData) {\n        _currentScore = score;\n    }\n\n    public bool HasFailedGoal() {\n        return false;\n    }\n\n    public void SetupGoal(GoalSetup[] setupData) {\n        var pointsGoal = setupData.First(data => data.goalEnum == GoalsEnum.TotalPointsGoal);\n        _amountToCollect = pointsGoal.maxPoints;\n        _currentScore = 0;\n    }\n}"
                                        }
                                    ]
                                },
                                {
                                    subSectionTitle: "MaxMovesGoal",
                                    paragraphs: [
                                        "This goal requires the player to complete the level within a specific number of moves.",
                                        "It tracks the number of moves made and fails if the player exceeds the limit."
                                    ],
                                    code: [
                                        {
                                            language: "csharp",
                                            content: "public class MaxMovesGoal : IGoal<int> {\n    private int _maxMoves;\n    private int _totalMoves;\n    public GoalsEnum Goal => GoalsEnum.MaxMovesGoal;\n\n    public MaxMovesGoal() {\n        _totalMoves = 0;\n        _maxMoves = int.MaxValue;\n    }\n\n    public string GetDescription() {\n        return $\"Beat this level with less than {_maxMoves} moves!\";\n    }\n\n    public string GetProgress() {\n        return $\"{_maxMoves - _totalMoves} left!\";\n    }\n\n    public bool HasFailedGoal() {\n        return _totalMoves >= _maxMoves;\n    }\n\n    public bool IsGoalCompleted() {\n        return _maxMoves >= _totalMoves;\n    }\n\n    public void SetupGoal(GoalSetup[] setupData) {\n        var maxMovesGoal = setupData.First(data => data.goalEnum == GoalsEnum.MaxMovesGoal);\n        _maxMoves = maxMovesGoal.maxPoints;\n        _totalMoves = 0;\n    }\n\n    public void UpdateProgress(int progressData) {\n        _totalMoves++;\n    }\n}"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            sectionTitle: "Why Use the Strategy Pattern Here?",
                            paragraphs: [
                                "The Strategy Pattern provides several benefits for the Goals system:",
                                "It makes it easy to introduce new goal types without modifying existing logic.",
                                "The game can dynamically switch between different goals at runtime, allowing for more flexible level designs."
                            ],
                            bulletPoints: [
                                "Encapsulation: Each goal type is a separate class, making the code easier to maintain.",
                                "Extensibility: New goals can be added without modifying the main game logic.",
                                "Modularity: Each goal behaves independently, making it easy to test and debug."
                            ]
                        },
                        {
                            sectionTitle: "Conclusion",
                            paragraphs: [
                                "The Strategy Pattern is a powerful way to handle different game objectives in a scalable and maintainable way.",
                                "By using this pattern, the Tile Matching Game ensures flexibility in defining objectives, making the game easier to expand in the future."
                            ]
                        },
                        {
                            sectionTitle: "Did You Like the Strategy Pattern?",
                            paragraphs: [
                                "If you found the Strategy Design Pattern interesting, stay tuned for upcoming posts!",
                                "I will share more examples of design patterns in video games to show how they can bring order and organization to development."
                            ]
                        },
                        {
                            sectionTitle: "Thank You for Reading!",
                            paragraphs: [
                                "If you have any questions or suggestions, leave us a comment—I'd love to discuss them with you."
                            ]
                        }   
                    ]
                },
            ]
          },
        post: {
            notFound: "Post not found.",
            error: "An error occurred while loading the post.",
            readMore: "Read more",
            goBack: "Back to blog",
        },          
        contact: "Contact",
        city_country: "Brasilia, Brazil",
        profile: "Profile",
        profile_description1: "I am a Brazilian-Italian game developer who holds a Unity Professional Certificate and a Bachelor's Degree in Computer Science. My passion for gaming, linguistics, and technology drives my professional journey.",
        profile_description2: "An enthusiast of Agile Methodologies, I am also a polyglot who enjoys learning new natural and programming languages. This blend of skills and interests uniquely positions me to contribute to innovative projects that require technical proficiency and creative problem-solving.",
        professional_exp: "Professional Experience",
        jobs: [{
            job_position: "Unity Game Engineer",
            company_name: "Fortis Games",
            location: "Brasilia, Brazil",
            period: "Oct 2023 - Current",
            descriptions: [{
                0: "Game Engineer responsible for LiveOps features for all new games from Fortis.",
                1: "Since Fortis is aquite new Company, we're creating our own SDKs/Tools to provide support to all of our in development games, and my responsible is to implement into the games what our backend team is developing.",
                2: "Created SDKs/Tools to support in-development games.",
                3: "Implemented features such as Interstitials system, In-game/IAP Store, Battlepass, Daily Rewards, and more using Unity3D (C#)."
            }]
        },
        {
            job_position: "Unity Game Engineer",
            company_name: "Azumo LLC",
            location: "Brasilia, Brazil",
            period: "Aug 2023 - Nov 2023",
            descriptions: [{
                0: "Contract Vendor Consultant for Zynga on Game of Thrones: Legends.",
                    1: "Implemented new gameplay and UI features such as upgrading characters and buildings.",
                    2: "I've worked on features such as upgrading characters and buildings, implementing the game FTUE and applying assets made by the Tech Art team.",
                    3: "I've also worked side by side with Designers and Producers from Zynga and attending many decision meeting for the game's core gameplay.",
                    4: "The game was built using Unity3D (C#) as well as many other Zynga's custom SDK/Tools."
            }]
        },
        {
            job_position: "Unity & C# Software Developer",
            company_name: "ELDORADO Institute",
            location: "Manaus, Brazil",
            period: "Feb 2023 - Oct 2023",
            descriptions: [{
                0: "Worked on refactoring an educational game integrated with Unity3D, originally developed in JavaScript. The game was designed to run on hardware with memory and size limitations, allowing it to be taken to hard-to-reach locations (such as cities in the middle of the Amazon rainforest).",
                1: "Besides Unity3D, I was also responsible for developing and supporting desktop applications in .NET Core and mobile applications in Kotlin.",
                2: "Worked on developing desktop and mobile software for reading and writing data on Nansen electricity measurement devices, one of the world's most traditional meter manufacturers.",
                3: "The applications use Unity3D (C#), .NET Core (WPF), Kotlin, among other technologies."
            }]
        },
        {
        job_position: "Game Engineer",
        company_name: "Wildlife Studios",
        location: "Sao Paulo, Brazil (Remote)",
        period: "Jun 2022 - Nov 2022",
        descriptions: [{
            0: "Game engineer responsible for supporting and developing new features for Wildlife live games in both client and server sides.",
            1: "I've worked in Cocos2D 'Block Craft 3D', a +1.0M daily active users voxel game about building whatever the player wants.",
            2: "I've worked also in Unity3D 'Suspects', a +250k daily active users Social Deduction game with integrated voice chat and proper progression system.",
            3: "Use of Scrum and Kanban frameworks in order to implement good team management practices that contribute to more agile development.",
            4: "The applications use Cocos2D (C++), Unity3D (C#), Node.JS, GoLang (Backend), Redis and MongoDB as technologies.",
        }],
        },
        {
            job_position: "Unity Software Developer",
            company_name: "Sidia Institute of Science and Technology",
            location: "Manaus, Brazil",
            period: "Apr 2021 - Jun 2022",
            descriptions: [{
                0: "Dev Team at Sidia Institute of Science and Technology's Augmented Reality team. Responsible for developing some confidential projects for various clients such Samsung by developing application for virtual reality glasses and augmented reality.",
                1: "Beside Unity Engine for AR/VR, C++ was also used in order to fulfill demands for Samsung Watch3 (Tizen OS).",
                2: "Use of Scrum framework in order to implement good team management practices that contribute to more agile development.",
                3: "The applications use, among other confidential technologies, Unity3D, C++, Microsoft's Mixed Reality Toolkit, SQL, Confluence and Jenkins as technologies.",
            }],
        },
        {
            job_position: "Fullstack Software Developer",
            company_name: "Grupo ICTS",
            location: "Brasilia, Brazil",
            period: "Aug 2020 - Mar 2021",
            descriptions: [{
                0: "Dev Team at SICOOB's life insurance team. Responsible for developing new features for the various applications involving human life insurance.",
                1: "Use of Scrum agile development framework in order to implement good team management practices that contribute to more agile development.",
                2: "The life insurance application uses Java EE, Spring, Hibernate, DB2, Angular, Adobe Flex, JBoss, Maven and Jenkins as technologies and frameworks.",
            }],
        },
        {
            job_position: "Fullstack Software Developer",
            company_name: "Globalweb Outsourcing",
            location: "Brasilia, Brazil",
            period: "Feb 2020 - Aug 2021",
            descriptions: [{
                0: "Dev Team at Caixa Econômica Federal on the web solution of Brazil's lottery. The website was about one year old at the moment and have already had more than two million people registered, which it corresponds to 80 million lottery bets.",
                1: "In the frontend, I've worked implementing new features to the website such notifications, shopping cart refactor and the addition to new bet modality.",
                2: "I've worked also implementing features for a web application for manager, so they could see statistics and analytics from the lotteria application.",
                3: "In the backend, I've Worked in the integration of the online lotteries backend with the payment API from Mercado Pago, a service similar to PayPal.",
                4: "I've Worked in definition of the application's database schema (DB2 database).",
                5: "I've Worked also in the integration of the online application through IBM's MQ queues with an already 30 years old system developed in COBOL",
                6: "The lottery web application uses Java, EJB, Ibm MQ, EasyRest, AngularJS, DB2, JBoss, MainFrame and OpenJPA as technologies.",
            }],
        },
        {
            job_position: "Research Asssistant - Fullstack Developer",
            company_name: "Brazilian Institute of Information in Science and Technology - IBICT",
            location: "Brasilia, Brazil",
            period: "Sep 2019 - Feb 2020",
            descriptions: [{
                0: "At the Brazilian Institute of Information Science and Technology I worked by analysing the information architecture of VISAO (Open Observatory System for Information Visualization), hosted at visao.ibict.br.",
                1: "My main goal there was to propose a data migration model for the application infrastructure and to prove it's viability. I also looked up for data persistence failures in the VISAO database.",
                2: "The VISAO project used HTML, JavaScript, Angular, PHP and MySQL as development tools",
            }],
        }],
        education_title: "Education",
        educations: [{
            course: "Postgraduate in Systems Development with Java",
            college: "Faculdade Unyleya",
            location: "Brasilia, Brazil",
            period: "Mar. 2019 - Nov. 2019"
        },
        {
            course: "Bachelor's Degree in Computer Science",
            college: "Universidade de Brasília - UnB",
            location: "Brasilia, Brazil",
            period: "Aug. 2014 - Dec. 2018"
        }],
        language_title: "Languages",
        portuguese: "Portuguese (Native)", 
        english: "English (Advanced)",
        italian: "Italian (Advanced)",
        french: "French (Basic)",
    }
};

export default translation;