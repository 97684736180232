import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../components/header";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import "./index.css";

const PostPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const posts = t("blog.posts", { returnObjects: true });
  const post = posts?.find((post) => post.id === parseInt(id, 10));

  if (!post) {
    return (
      <div className="post-page">
        <Header />
        <div className="post-page-error">
          <h1>{t("post.notFound", "Post não encontrado.")}</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="post-page">
      <Header />
      <section>
        <div className="post-container">
          <div className="post-header">
            <h1 className="post-title">{post.title}</h1>
          </div>

          {/* Se houver um Unity WebGL, exibir iframe */}
          {post.unityPath && (
            <div className="unity-container">
              <iframe
                className="unity-iframe"
                src={post.unityPath}
                title="Unity WebGL"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          )}

          {/* Renderiza conteúdo do post */}
          <div className="post-content">
            {post.content.map((section, index) => (
              <div key={index} className="post-section">
                <h2 className="post-section-title">{section.sectionTitle}</h2>

                {/* Renderiza parágrafos */}
                {section.paragraphs &&
                  section.paragraphs.map((paragraph, pIndex) => (
                    <p key={pIndex} className="post-paragraph">
                      {paragraph}
                    </p>
                  ))}

                {/* Renderiza bullet points se existirem */}
                {section.bulletPoints && (
                  <ul className="post-bullets">
                    {section.bulletPoints.map((point, bpIndex) => (
                      <li key={bpIndex}>{point}</li>
                    ))}
                  </ul>
                )}

                {/* Renderiza código se existir na seção */}
                {section.code &&
                  section.code.map((codeBlock, cIndex) => (
                    <SyntaxHighlighter
                      key={cIndex}
                      language={codeBlock.language}
                      style={coy}
                      className="code-block"
                    >
                      {codeBlock.content}
                    </SyntaxHighlighter>
                  ))}

                {/* Renderiza subseções se existirem */}
                {section.subSections &&
                  section.subSections.map((subSection, subIndex) => (
                    <div key={subIndex} className="post-subsection">
                      <h3 className="post-subsection-title">
                        {subSection.subSectionTitle}
                      </h3>

                      {/* Renderiza parágrafos da subseção */}
                      {subSection.paragraphs &&
                        subSection.paragraphs.map((subPara, subPIndex) => (
                          <p key={subPIndex} className="post-paragraph">
                            {subPara}
                          </p>
                        ))}

                      {/* Renderiza bullet points da subseção */}
                      {subSection.bulletPoints && (
                        <ul className="post-bullets">
                          {subSection.bulletPoints.map((point, sbpIndex) => (
                            <li key={sbpIndex}>{point}</li>
                          ))}
                        </ul>
                      )}

                      {/* Renderiza código da subseção */}
                      {subSection.code &&
                        subSection.code.map((subCodeBlock, subCIndex) => (
                          <SyntaxHighlighter
                            key={subCIndex}
                            language={subCodeBlock.language}
                            style={coy}
                            className="code-block"
                          >
                            {subCodeBlock.content}
                          </SyntaxHighlighter>
                        ))}

                      {/* Renderiza uso (usage) se existir */}
                      {subSection.usage && (
                        <p className="post-usage">
                          <strong>Usage:</strong> {subSection.usage}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
          
          {/* Adicionar link para o GitHub se existir */}
          {post.gitHubUrl && (
            <div className="github-link">
              <a href={post.gitHubUrl} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github"></i> {t('blog.viewOnGithub')}
              </a>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default PostPage;
